/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

import 'popper.js';
import 'bootstrap';
import Mark from 'mark.js';
import tippy from 'tippy.js';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import * as Sentry from '@sentry/browser'; // TODO: we should replace this with @sentry/react
import { BrowserTracing } from "@sentry/tracing";

import '../src/tooltips';
import '../src/assessment_filter_quiublets';
import '../src/audit_filter_quiublets';

import '../stylesheets/application.sass';

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

// TODO: thats baaaaaad practice — please refactor
window.Mark = Mark;
window.tippy = tippy;
window.Sentry = Sentry;
window.BrowserTracing = BrowserTracing;

Turbolinks.start();
ActiveStorage.start();

// Having both rails_ujs and jquery_ujs is conflicting.
// When migration to rails_ujs (from jquery_ujs) is complete, below lines can be uncommented

// import Rails from "@rails/ujs";
// window.Rails = Rails;
// Rails.start();
