// filter button count is not 0
function buttonCount(button) {
  return button.firstChild.textContent !== '0';
}

// hide audit answers that have another status
function hideAuditAnswers(statusClass) {
  document.querySelector('#quiubletAuditAccordion').classList.remove('d-none');

  const answers = document.querySelectorAll(
    '#quiubletAuditAccordion .assessmentAnswer'
  );
  const answersArray = Array.from(answers);

  answersArray.filter((answer) => {
    const answerStatus = answer.querySelector('.assessmentAnswer-status');
    const status =
      answerStatus === null
        ? true
        : answerStatus.classList.value.includes(statusClass);

    if (status === false) {
      return answer.classList.add('d-none');
    }

    return answer.classList.remove('d-none');
  });
}

// hide audit answers that have another status besides not applicable and not auditable
function hideAuditNevermindAnswers(statusClass) {
  document.querySelector('#quiubletAuditAccordion').classList.remove('d-none');

  const nevermindAnswers = document.querySelectorAll(
    '#quiubletAuditAccordion .assessmentAnswer'
  );
  const nevermindAnswersArray = Array.from(nevermindAnswers);

  nevermindAnswersArray.filter((answer) => {
    const statusText = answer.querySelector(
      '.assessmentAnswer-status'
    ).textContent;
    const buttonText = document.querySelector(
      `.auditProcessProgress-quiublet ${statusClass} span`
    ).textContent;

    if (statusText !== buttonText) {
      return answer.classList.add('d-none');
    }
    return answer.classList.remove('d-none');
  });
}

// hide accordion item if all answers have another status
function hideAccordionItem() {
  const accordionItems = document.querySelectorAll(
    '#quiubletAuditAccordion .accordion-item'
  );
  const accordionItemsArray = Array.from(accordionItems);

  accordionItemsArray.filter((item) => {
    const itemAnswerLength = item.querySelectorAll('.assessmentAnswer').length;
    const hiddenLength = item.querySelectorAll('.d-none').length;

    if (itemAnswerLength === hiddenLength) {
      return item.classList.add('d-none');
    }
    return item.classList.remove('d-none');
  });
}

function filterStyles(statusClass) {
  // fade other pill buttons
  const fadeButtons = document.querySelectorAll(
    `.auditProcessProgress-quiublet > .rounded-pill:not(${statusClass})`
  );

  fadeButtons.forEach((button) => button.classList.add('opacity-50'));
  document
    .querySelector(`.auditProcessProgress-quiublet ${statusClass}`)
    .classList.remove('opacity-50');

  // display show all button
  document
    .querySelector('.auditProcessProgress-quiublet .bg-primary-light')
    .classList.remove('d-none');

  // add border-top to first accordion item after filtering
  document
    .querySelectorAll('.accordion-item')
    .forEach((item) => item.classList.remove('border-top'));
  const visibleItems = document.querySelectorAll(
    '.accordion-item:not(.d-none)'
  );
  if (visibleItems.length !== 0) {
    visibleItems[0].classList.add('border-top');
  }
}

document.addEventListener('turbolinks:load', () => {
  const quiubletAuditAccordion = document.querySelector(
    '#quiubletAuditAccordion'
  );
  const showAllButton = document.querySelector(
    '.auditProcessProgress-quiublet .bg-primary-light'
  );

  if (document.body.contains(showAllButton)) {
    // hide show all button before filters have been clicked
    showAllButton.classList.add('d-none');

    // display all answers when clicking show all button by reloading the page
    showAllButton.onclick = () => window.location.reload();
  }

  // audit filter for status success
  const successButton = document.querySelector(
    '.auditProcessProgress-quiublet .success'
  );
  const successButtonNumber =
    document.body.contains(successButton) && buttonCount(successButton);
  const successButtonZero =
    document.body.contains(successButton) &&
    buttonCount(successButton) === false;

  if (successButtonNumber) {
    successButton.onclick = () => {
      hideAuditAnswers('assessmentAnswer-statusPassed');
      hideAccordionItem();
      filterStyles('.success');
    };
  }

  if (successButtonZero) {
    successButton.onclick = () => {
      quiubletAuditAccordion.classList.add('d-none');
      filterStyles('.success');
    };
  }

  // audit filter for status failed
  const failedButton = document.querySelector(
    '.auditProcessProgress-quiublet .failed'
  );
  const failedButtonNumber =
    document.body.contains(failedButton) && buttonCount(failedButton);
  const failedButtonZero =
    document.body.contains(failedButton) && buttonCount(failedButton) === false;

  if (failedButtonNumber) {
    failedButton.onclick = () => {
      hideAuditAnswers('assessmentAnswer-statusFailed');
      hideAccordionItem();
      filterStyles('.failed');
    };
  }

  if (failedButtonZero) {
    failedButton.onclick = () => {
      quiubletAuditAccordion.classList.add('d-none');
      filterStyles('.failed');
    };
  }

  // audit filter for status notapplicable
  const notapplicableButton = document.querySelector(
    '.auditProcessProgress-quiublet .notapplicable'
  );
  const notapplicableButtonNumber =
    document.body.contains(notapplicableButton) &&
    buttonCount(notapplicableButton);
  const notapplicableButtonZero =
    document.body.contains(notapplicableButton) &&
    buttonCount(notapplicableButton) === false;

  if (notapplicableButtonNumber) {
    notapplicableButton.onclick = () => {
      hideAuditNevermindAnswers('.notapplicable');
      hideAccordionItem();
      filterStyles('.notapplicable');
    };
  }

  if (notapplicableButtonZero) {
    notapplicableButton.onclick = () => {
      quiubletAuditAccordion.classList.add('d-none');
      filterStyles('.notapplicable');
    };
  }

  // audit filter for status not_auditable
  const notauditableButton = document.querySelector(
    '.auditProcessProgress-quiublet .not_auditable'
  );
  const notauditableButtonNumber =
    document.body.contains(notauditableButton) &&
    buttonCount(notauditableButton);
  const notauditableButtonZero =
    document.body.contains(notauditableButton) &&
    buttonCount(notauditableButton) === false;

  if (notauditableButtonNumber) {
    notauditableButton.onclick = () => {
      hideAuditNevermindAnswers('.not_auditable');
      hideAccordionItem();
      filterStyles('.not_auditable');
    };
  }

  if (notauditableButtonZero) {
    notauditableButton.onclick = () => {
      quiubletAuditAccordion.classList.add('d-none');
      filterStyles('.not_auditable');
    };
  }

  // audit filter for status todo
  const todoButton = document.querySelector(
    '.auditProcessProgress-quiublet .todo'
  );
  const todoButtonNumber =
    document.body.contains(todoButton) && buttonCount(todoButton);
  const todoButtonZero =
    document.body.contains(todoButton) && buttonCount(todoButton) === false;

  if (todoButtonNumber) {
    todoButton.onclick = () => {
      hideAuditAnswers('assessmentAnswer-statusOpen');
      hideAccordionItem();
      filterStyles('.todo');
    };
  }

  if (todoButtonZero) {
    todoButton.onclick = () => {
      quiubletAuditAccordion.classList.add('d-none');
      filterStyles('.todo');
    };
  }
});
