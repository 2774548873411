import { delegate } from 'tippy.js';

document.addEventListener('turbolinks:load', () => {
  delegate('.qu-body', {
    target: '[data-tippy-content]',
    theme: 'material',
    popperOptions: {
      strategy: 'fixed'
    }
  });
});
